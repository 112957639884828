/* Container for Animation */
.animationContainer {
  position: relative;
  width: 100%;
  max-width: 360px;
  margin: auto;
}

/* Website Frame */
.websiteFrame {
  width: 100%;
  background: #f0f7ff;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
  transform-style: preserve-3d;
}

/* Header */
.header {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  background: linear-gradient(135deg, #4a90e2, #56c2e6);
  color: #fff;
  border-radius: 8px;
  animation: slideInLeft 2s infinite alternate ease-in-out;
}

.headerIcon {
  width: 26px;
  height: 26px;
  background: #ff6584;
  border-radius: 50%;
  transform: translateZ(20px);
}

.navIcon {
  width: 16px;
  height: 16px;
  background: #28d3ff;
  opacity: 0.9;
  border-radius: 4px;
  transform: translateZ(20px);
}

/* Sidebar */
.sidebar {
  width: 15%;
  background: #333;
  height: 160px;
  border-radius: 8px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: floatSidebar 3s ease-in-out infinite alternate;
}

.sidebarIcon {
  width: 20px;
  height: 20px;
  background: #f1f1f1;
  border-radius: 3px;
  animation: pulseBlock 2s infinite ease-in-out;
}

/* Content Area */
.contentArea {
  flex: 1;
  background: #fff;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  animation: floatContent 3s infinite alternate ease-in-out;
  transform: translateZ(30px);
}

.contentBlock {
  background: #666;
  height: 12px;
  border-radius: 4px;
}

.iconButton {
  background: #28d3ff;
  color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  align-self: center;
  animation: bounceButton 2s infinite ease-in-out;
}

/* Footer */
.footer {
  background: #333;
  padding: 12px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  animation: slideInUp 2s ease-in-out infinite alternate;
}

.footerIcon {
  width: 30px;
  height: 30px;
  background: #fff;
  border-radius: 50%;
  animation: pulseBlock 1.5s infinite ease-in-out;
}

/* Animations */
@keyframes slideInLeft {
  0% { transform: translateX(-20px); }
  100% { transform: translateX(10px); }
}

@keyframes floatSidebar {
  0% { transform: translateY(5px); }
  100% { transform: translateY(-5px); }
}

@keyframes floatContent {
  0% { transform: translateX(5px); }
  100% { transform: translateX(-5px); }
}

@keyframes slideInUp {
  0% { transform: translateY(10px); }
  100% { transform: translateY(-10px); }
}

@keyframes bounceButton {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
}

@keyframes pulseBlock {
  0%, 100% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.8; }
}
