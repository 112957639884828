@keyframes moveText {
    0%, 100% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px);
    }
}

@keyframes upDownMotion {
    0%, 100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-5px);
    }
}

.animateText {
    animation: moveText 3s ease-in-out infinite;
}

.animateMotion {
    animation: upDownMotion 3s ease-in-out infinite;
}
