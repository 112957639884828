/* index.css or global.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Apply a global background color and text color */
body {
    @apply bg-[#F2F4FF] text-[#262C55];
    margin: 0; /* Removes default margin */
    padding: 0; /* Removes default padding */
}
