/* Logo Animation */
.logoContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}
.logoText {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff; /* Default text color for fallback */
    background: linear-gradient(45deg, #6EE7B7, #3B82F6); /* Gradient colors for the logo */
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    animation: scaleRotate 3s ease-in-out infinite;
}

/* Hover Effects for Icons */
.iconHover {
    transition: transform 0.4s ease, color 0.4s ease;
}
.iconHover:hover {
    transform: rotate(15deg) scale(1.2);
    color: #6EE7B7; /* Lighter green on hover */
}

/* Service Links Animation */
.linkAnimation {
    position: relative;
    display: inline-block;
    background: linear-gradient(45deg, #3B82F6, #6EE7B7); /* Gradient colors for the links */
    -webkit-background-clip: text;
    color: transparent;
    transition: background-size 0.3s ease, color 0.3s ease;
}
.linkAnimation:hover {
    background-size: 200%;
    color: #ffffff; /* Change to white when hovered */
}

/* Fade In for Sections */
.fadeIn {
    opacity: 0;
    animation: fadeUp 1s forwards;
}
.sectionHeader {
    animation-delay: 0.3s;
}

/* Scale and Rotate Animation for Logo */
@keyframes scaleRotate {
    0%, 100% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.1) rotate(10deg);
    }
}

/* Fade-Up Entrance Animation */
@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(15px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Light Pulse Effect for Language Select */
.languageSelect {
    transition: box-shadow 0.3s ease;
}
.languageSelect:hover {
    box-shadow: 0 0 10px #6EE7B7; /* Light green shadow */
}
