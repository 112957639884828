@keyframes typing {
  0% {
      width: 0;
  }
  100% {
      width: 10ch; /* Adjust to fit the length of "Imgify AI" */
  }
}

@keyframes blink-caret {
  from, to {
      border-color: transparent;
  }
  50% {
      border-color: black;
  }
}

.typing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid black;
  animation: typing 3s steps(18, end) infinite, blink-caret 0.75s step-end infinite;
  width: 17ch; /* Reserve space for "Imgify AI" */
  font-size: inherit; /* Ensure the font size is inherited */
  line-height: inherit; /* Ensure the line height is inherited */
  vertical-align: bottom; /* Align vertically with other text */
}
